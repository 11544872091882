import React from 'react';

const Logo = () => {
  return (
    <svg width="112" height="49" viewBox="0 0 112 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.6543 12.9758H49.0566C49.425 12.9325 49.7983 12.9664 50.1528 13.0753C50.5073 13.1843 50.8353 13.3659 51.1158 13.6085C51.4808 14.0101 51.6801 14.5351 51.6734 15.0778C51.6794 15.3902 51.6095 15.6993 51.4697 15.9787C51.2961 16.2614 51.0747 16.5118 50.8155 16.7187C51.3103 16.854 51.7403 17.1622 52.0273 17.5874C52.2591 17.957 52.3818 18.3845 52.3813 18.8207C52.3998 19.1555 52.3396 19.49 52.2055 19.7973C52.0714 20.1046 51.8672 20.3762 51.6091 20.5903C50.9871 21.0394 50.2303 21.2626 49.4642 21.223H46.6543V12.9758ZM48.4346 14.3593V16.1717H48.8421C49.1205 16.1763 49.3897 16.0725 49.5929 15.8822C49.6917 15.7928 49.7706 15.6836 49.8242 15.5616C49.8778 15.4396 49.9049 15.3076 49.9039 15.1743C49.9039 15.0608 49.8767 14.9489 49.8244 14.8481C49.7722 14.7473 49.6964 14.6606 49.6036 14.5952C49.3881 14.4322 49.1228 14.3488 48.8529 14.3593H48.4346ZM48.4346 17.5767V19.861H49.0673C50.0433 19.861 50.5366 19.4964 50.5366 18.7885C50.5493 18.622 50.523 18.4548 50.4597 18.3003C50.3964 18.1458 50.2979 18.0081 50.172 17.8984C50.0235 17.7763 49.8522 17.6849 49.6681 17.6297C49.484 17.5744 49.2907 17.5564 49.0995 17.5767H48.4346Z"
        fill="#F15E32"
      />
      <path
        d="M53.4434 12.9761H58.0871V14.6062H55.2237V16.2793H58.0871V17.888H55.2237V19.5932H58.0871V21.2233H53.4434V12.9761Z"
        fill="#F15E32"
      />
      <path d="M58.9873 12.9761H64.5319V14.7349H62.623V21.2233H60.8427V14.7349H58.9873V12.9761Z" fill="#F15E32" />
      <path d="M65.0576 12.9761H70.613V14.7349H68.7147V21.2233H66.9452V14.7349H65.0576V12.9761Z" fill="#F15E32" />
      <path
        d="M71.7393 12.9761H76.383V14.6062H73.5196V16.2793H76.383V17.888H73.5196V19.5932H76.383V21.2233H71.7393V12.9761Z"
        fill="#F15E32"
      />
      <path
        d="M77.8091 12.9762H80.5224C81.2362 12.9356 81.9384 13.1697 82.485 13.6304C82.7266 13.8551 82.916 14.13 83.0401 14.4357C83.1643 14.7413 83.22 15.0705 83.2036 15.4C83.1951 15.8593 83.0814 16.3106 82.8711 16.7191C82.6116 17.2125 82.1805 17.5939 81.6592 17.7916L83.965 21.2235H81.9273L79.7824 18.0061H79.5786V21.2235H77.8091V12.9762ZM79.6001 14.4455V16.5904H79.9862C80.9085 16.5904 81.3804 16.2043 81.3804 15.4536C81.3857 15.3097 81.3552 15.1667 81.2915 15.0375C81.2279 14.9084 81.1331 14.797 81.0158 14.7136C80.7188 14.5139 80.365 14.416 80.0076 14.4347L79.6001 14.4455Z"
        fill="#F15E32"
      />
      <path
        d="M87.9976 12.9761H89.5848L90.9147 18.6065L92.2981 12.9761H93.3706L94.7434 18.628L96.0947 12.9761H97.6819L95.6764 21.2233H94.0677L92.8558 16.172L91.6439 21.2233H89.9709L87.9976 12.9761Z"
        fill="#F15E32"
      />
      <path
        d="M101.103 12.9761H102.486L105.843 21.2233H104.031L103.377 19.6039H100.159L99.5157 21.2233H97.7998L101.103 12.9761ZM101.789 15.6787L100.813 18.1132H102.776L101.789 15.6787Z"
        fill="#F15E32"
      />
      <path
        d="M104.986 12.9761H106.96L108.483 15.5393L110.027 12.9761H112L109.394 17.3732V21.2233H107.635V17.3732L104.986 12.9761Z"
        fill="#F15E32"
      />
      <path
        d="M46.4074 31.0687C46.3893 30.5108 46.4867 29.9551 46.6934 29.4365C46.9001 28.9179 47.2116 28.4476 47.6086 28.0551C47.9831 27.6738 48.4307 27.3719 48.9245 27.1673C49.4183 26.9628 49.9483 26.8599 50.4828 26.8647C51.3153 26.878 52.126 27.1334 52.8159 27.5996C53.5058 28.0659 54.0451 28.7228 54.368 29.4903C54.6909 30.2578 54.7835 31.1027 54.6344 31.9219C54.4854 32.7411 54.1011 33.4992 53.5286 34.1038C53.1397 34.4996 52.6731 34.8106 52.1581 35.0174C51.6432 35.2241 51.091 35.322 50.5364 35.305C49.9848 35.3229 49.4356 35.2254 48.924 35.0185C48.4124 34.8117 47.9497 34.5001 47.5657 34.1038C47.1794 33.7011 46.8779 33.225 46.679 32.7036C46.48 32.1823 46.3877 31.6264 46.4074 31.0687ZM50.5578 28.5055C50.2443 28.4907 49.9315 28.5477 49.6434 28.6723C49.3553 28.7968 49.0995 28.9855 48.8955 29.2241C48.4694 29.7428 48.2448 30.3977 48.2628 31.0687C48.2418 31.7374 48.4756 32.3891 48.917 32.8919C49.1213 33.1215 49.3727 33.3045 49.6539 33.4284C49.9352 33.5524 50.2398 33.6145 50.5471 33.6105C50.8608 33.6221 51.1732 33.5647 51.4623 33.4424C51.7513 33.3201 52.0101 33.1359 52.2202 32.9026C52.6674 32.3785 52.8976 31.7033 52.8636 31.0151C52.8928 30.3424 52.6668 29.6836 52.2309 29.1705C52.0176 28.942 51.758 28.7617 51.4695 28.6415C51.181 28.5213 50.8702 28.464 50.5578 28.4734V28.5055Z"
        fill="#F15E32"
      />
      <path
        d="M56.146 26.9185H60.7898V28.5164H57.9156V30.2002H60.7898V31.8089H57.9156V35.1657H56.146V26.9185Z"
        fill="#F15E32"
      />
      <path d="M65.6377 26.9185H67.3965V33.3533H69.831V35.1228H65.6377V26.9185Z" fill="#F15E32" />
      <path d="M70.9351 26.9185H72.7154V35.1657H70.9351V26.9185Z" fill="#F15E32" />
      <path
        d="M74.3135 26.9185H78.9573V28.5164H76.083V30.2002H78.9573V31.8089H76.1474V35.1657H74.3135V26.9185Z"
        fill="#F15E32"
      />
      <path
        d="M80.437 26.9185H85.0808V28.5486H82.1959V30.2217H85.0593V31.8303H82.1959V33.5356H85.0593V35.1657H80.437V26.9185Z"
        fill="#F15E32"
      />
      <path
        d="M23.2854 4.4286C21.8054 2.5409 19.7852 1.14919 17.4941 0.439024C15.8433 -0.0135975 14.1165 -0.117665 12.4233 0.133444C10.7302 0.384553 9.10785 0.985339 7.65954 1.89758C5.53101 3.31849 3.77625 5.23143 2.54388 7.4744C-3.21526 17.2338 1.0746 32.6452 12.6787 46.5443C18.8346 43.1553 21.001 35.3478 21.0975 28.7521C18.6309 30.5431 15.4778 33.4173 12.4427 33.1707C12.3967 33.1671 12.3523 33.1521 12.3135 33.1271C12.2747 33.1022 12.2427 33.0679 12.2204 33.0276C12.1981 32.9872 12.1861 32.9419 12.1856 32.8957C12.1851 32.8496 12.1961 32.804 12.2175 32.7631C14.0729 28.9988 19.8535 27.8405 20.7972 23.4541C21.2477 19.6254 14.1909 17.5127 10.8126 18.7782C18.2341 13.5017 26.0416 22.1028 29.8381 5.32947C31.7686 10.7776 27.4144 15.1318 26.0952 20.344C24.5509 26.9397 25.2265 34.286 21.7196 38.7475C29.6236 32.9669 38.5358 28.1301 41.3779 17.5341C44.0376 6.40193 30.7283 -2.81055 23.2854 4.4286ZM21.3335 14.2953C20.6936 14.2146 20.1108 13.8866 19.7099 13.3814C19.3089 12.8762 19.1218 12.2341 19.1885 11.5927C19.1218 10.9512 19.3089 10.3091 19.7099 9.80393C20.1108 9.29876 20.6936 8.97071 21.3335 8.89005C21.9733 8.97071 22.5562 9.29876 22.9571 9.80393C23.358 10.3091 23.5452 10.9512 23.4784 11.5927C23.5452 12.2341 23.358 12.8762 22.9571 13.3814C22.5562 13.8866 21.9733 14.2146 21.3335 14.2953Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M25.3665 48.282C24.6898 48.282 24.0284 48.0814 23.4658 47.7055C22.9032 47.3295 22.4647 46.7952 22.2057 46.1701C21.9468 45.545 21.879 44.8571 22.0111 44.1934C22.1431 43.5298 22.4689 42.9202 22.9473 42.4417C23.4258 41.9633 24.0354 41.6374 24.699 41.5054C25.3627 41.3734 26.0506 41.4412 26.6757 41.7001C27.3008 41.9591 27.8352 42.3976 28.2111 42.9602C28.587 43.5228 28.7876 44.1842 28.7876 44.8609C28.7876 45.7682 28.4272 46.6384 27.7856 47.28C27.144 47.9216 26.2738 48.282 25.3665 48.282ZM25.3665 41.7078C24.7429 41.7078 24.1333 41.8927 23.6147 42.2392C23.0962 42.5857 22.6921 43.0781 22.4534 43.6542C22.2148 44.2304 22.1524 44.8644 22.274 45.476C22.3957 46.0876 22.696 46.6494 23.1369 47.0904C23.5779 47.5314 24.1397 47.8317 24.7514 47.9533C25.363 48.075 25.997 48.0125 26.5731 47.7739C27.1492 47.5353 27.6417 47.1311 27.9881 46.6126C28.3346 46.0941 28.5195 45.4845 28.5195 44.8609C28.5195 44.0246 28.1873 43.2226 27.596 42.6313C27.0047 42.04 26.2027 41.7078 25.3665 41.7078Z"
        fill="#F15E32"
      />
      <path
        d="M24.5615 43.5306H25.1407C25.3946 43.5159 25.6454 43.5919 25.8485 43.7451C25.9342 43.8077 26.0034 43.8902 26.0501 43.9855C26.0968 44.0808 26.1196 44.186 26.1166 44.2921C26.12 44.4652 26.0588 44.6334 25.945 44.7639C25.7999 44.9185 25.6075 45.0204 25.398 45.0535L26.1702 46.1903H25.7412L25.0012 45.1178H24.9154V46.1903H24.5615V43.5306ZM24.9262 43.8523V44.7747C25.4624 44.7747 25.7412 44.6352 25.7412 44.3135C25.7461 44.2116 25.7115 44.1117 25.6447 44.0347C25.5756 43.9603 25.486 43.9081 25.3873 43.8845C25.235 43.859 25.0806 43.8482 24.9262 43.8523Z"
        fill="#F15E32"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="20.862" y1="0" x2="20.862" y2="46.5443" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F26133" />
          <stop offset="1" stopColor="#FAA536" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Logo;
