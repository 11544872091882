import React, { useContext } from 'react';
import { Link } from 'gatsby';

import ShoppingBagPrimary from '@assets/cart-drop-down/shopping-bag-primary';
import BlogButton from '@components/reusable-buttons/blog-button';
import CartDataContainer from './cart-data-container';
import CartContext from '../../context/cart-context';
import LanguageContext from '../../context/language-context';
import CloseNotification from '@assets/svg/close-notification.svg';

const CartDropDown = ({ setShowCartDropDown }) => {
  const { checkout } = useContext(CartContext);
  const { contentLanguage } = useContext(LanguageContext);
  function enableScroll() {
    window.onscroll = function () {};
  }

  return (
    <div className="cart-drop-down-container">
      <div
        className="remove-button-cart"
        onClick={() => {
          setShowCartDropDown(false);
          enableScroll();
        }}
      >
        <img src={CloseNotification} />
      </div>
      <div className="cart-drop-down-empty-holder">
        <h5>{contentLanguage === 'eng' ? 'Your shopping cart.' : 'Tu carrito de la compra.'}</h5>

        {checkout?.lineItems.length > 0 ? (
          <CartDataContainer />
        ) : (
          <div className="cart-bag-button">
            <ShoppingBagPrimary />
            <h5>
              {contentLanguage === 'eng'
                ? 'You have no items in your shopping cart'
                : 'No tiene artículos en su carrito de compras'}
            </h5>
            <Link to="/books">
              <div className="browse-books-cart-button" onClick={enableScroll}>
                <BlogButton buttonText={contentLanguage === 'eng' ? 'Browse Books' : 'Explorar libros'} />
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
export default CartDropDown;
