import React from 'react';

export const Loader = ({}) => {
  return (
    <div className="loader-container">
      <div>
        <div className="loader inline-block">
          <span className="loader-highlight loader-spin-a"></span>
          <span className="loader-highlight loader-spin-b"></span>
          <span className="loader-highlight loader-spin-c"></span>
        </div>
        <div className="loader-overlay"></div>
      </div>
    </div>
  );
};
