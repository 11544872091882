import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import { globalHistory } from '@reach/router';

import Arrow from '@assets/svg/arrow';
import Logo from '@assets/svg/logo';
import Menu from '@assets/svg/menu';
import ShoppingBag from '@assets/svg/shopping-bag';
import CartContext from '../../context/cart-context';
import PopMenuHeader from '../pop-up-menu/pop-up-menu';
import CartDropDown from '../cart-drop-down/cart-drop-down';
import Backdrop from '../backdrop/backdrop';
import LanguageMenu from '../language-menu/language-menu';
import LanguageContext from '../../context/language-context';
import Globe from '@assets/social-icons/globe.png';

const Header = () => {
  const [path, setPath] = useState(globalHistory.location.pathname);

  const { geolocationStatus, checkout } = useContext(CartContext);
  const { contentLanguage } = useContext(LanguageContext);
  const [showMenu, setShowMenu] = useState(false);
  const [showCartDropDown, setShowCartDropDown] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);

  function avoidScroll() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }
  function enableScroll() {
    window.onscroll = function () {};
  }
  let totalQuantity = 0;

  if (checkout) {
    checkout.lineItems.forEach((lineItem) => {
      totalQuantity = totalQuantity + lineItem.quantity;
    });
  }

  return (
    <div className="sticky-header-container">
      <div className="common-container">
        <div className="header-bowl">
          <div className="logo-holder">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className="options-holder">
            <div className={path === '/blog' ? 'options options-books add-active-state' : 'options options-books'}>
              <Link to="/blog">
                <p className="options-text">Blogs</p>
              </Link>
            </div>
            <div className={path === '/books' ? 'options options-books add-active-state' : 'options options-books'}>
              <Link to="/books">
                <p className="options-text">
                  {contentLanguage === null ? 'Books' : contentLanguage === 'eng' ? 'Books' : 'Libros'}
                </p>
              </Link>
            </div>

            <div
              id="languageButton"
              className="options options-language"
              onMouseOver={() => setShowLanguage(true)}
              onMouseLeave={() => setShowLanguage(false)}
            >
              <div className="options-internal-container">
                <div className="flag-holder-header">
                  <img src={Globe} />
                </div>

                <p className="options-text line-height-modified">
                  {contentLanguage === null ? 'English' : contentLanguage === 'eng' ? 'English' : 'Español'}
                </p>
                <Arrow />
                <div className={showLanguage ? 'language-switch-holder show' : 'hide'}>
                  <LanguageMenu showLanguage setShowLanguage={setShowLanguage} />
                </div>
              </div>
            </div>
            {geolocationStatus ? (
              <div
                className="options options-shopping-bag"
                onClick={() => {
                  setShowCartDropDown(!showCartDropDown);
                  avoidScroll();
                }}
              >
                <ShoppingBag />

                {totalQuantity === 0 ? (
                  ''
                ) : (
                  <div className="cart-item-counter">
                    <h6>{totalQuantity}</h6>
                  </div>
                )}
              </div>
            ) : (
              ''
            )}
            <div
              className="options"
              onClick={() => {
                setShowMenu(true);
                avoidScroll();
              }}
            >
              <Menu />
            </div>
          </div>
        </div>
        {showCartDropDown && (
          <CartDropDown showCartDropDown={setShowCartDropDown} setShowCartDropDown={setShowCartDropDown} />
        )}
        <div
          className={showCartDropDown ? 'show' : 'hide '}
          onClick={() => {
            setShowCartDropDown(!showCartDropDown);
            enableScroll();
          }}
        >
          <Backdrop />
        </div>
      </div>
      <div className={showMenu ? 'pop-up-menu-holder  ' : ' hide-popup '}>
        <PopMenuHeader setShowMenu={setShowMenu} showMenuStatus={showMenu} enableScroll={enableScroll} />
      </div>
      <div
        className={showMenu ? 'show' : 'hide'}
        onClick={() => {
          setShowMenu(!showMenu);
          enableScroll();
        }}
      >
        <Backdrop />
      </div>
    </div>
  );
};
export default Header;
