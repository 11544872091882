import React from 'react';

export default function ShoppingBagPrimary() {
  return (
    <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5 6L9.5 18V60C9.5 61.5913 10.1321 63.1174 11.2574 64.2426C12.3826 65.3679 13.9087 66 15.5 66H57.5C59.0913 66 60.6174 65.3679 61.7426 64.2426C62.8679 63.1174 63.5 61.5913 63.5 60V18L54.5 6H18.5Z"
        stroke="#F15E32"
        stroke-width="7.08333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M9.5 18H63.5" stroke="#F15E32" stroke-width="7.08333" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M48.5 30C48.5 33.1826 47.2357 36.2348 44.9853 38.4853C42.7348 40.7357 39.6826 42 36.5 42C33.3174 42 30.2652 40.7357 28.0147 38.4853C25.7643 36.2348 24.5 33.1826 24.5 30"
        stroke="#F9BFAD"
        stroke-width="7.08333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
