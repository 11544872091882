import React from 'react';

const Menu = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 6.14111H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3 18.1411H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default Menu;
