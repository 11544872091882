import React, { useContext, useState } from 'react';

import DeleteButton from '@assets/cart-drop-down/delete-button.svg';
import BlogButton from '@components/reusable-buttons/blog-button';
import increment from '@assets/book-details/increment.svg';
import decrement from '@assets/book-details/decrement.svg';
import CartContext from '../../context/cart-context';
import LanguageContext from '../../context/language-context';

export default function CartDataContainer() {
  const { checkout, updateLineItem, removeLineItem } = useContext(CartContext);
  const { contentLanguage } = useContext(LanguageContext);
  const handleQuantityIn = (id) => {
    updateLineItem({ variantId: id, quantity: 1 });
  };
  const handleQuantityDec = (id) => {
    updateLineItem({ variantId: id, quantity: -1 });
  };

  const handleCheckout = () => {
    window.open(checkout?.webUrl);
  };

  const handleCickRemove = (lineItemId) => {
    removeLineItem(lineItemId);
  };

  return (
    <div className="cart-drop-down-with-data">
      <div className="cart-select-all"></div>

      <div className="cart-added-books-container">
        {checkout?.lineItems?.map((book) => {
          return (
            <div className="cart-added-books-info">
              <div className="cart-book-amount cursor-pointer" onClick={() => handleCickRemove(book?.id)}>
                <img src={DeleteButton} />
              </div>

              <div className="cart-added-books-image">
                <img src={book.variant.image.src} />
              </div>

              <div className="cart-added-book-info-wrapper">
                <p className="cart-added-book-name body2">{book.title}</p>
                <div className="cart-book-quantity">
                  <h6 className="cart-book-quantity-title body2">
                    {contentLanguage === 'eng' ? 'No. of items' : 'No. de artículos'}
                  </h6>
                  <div className="books-quantity-controller cart-modified">
                    <div
                      className="quantity-in-decrementer"
                      onClick={() => {
                        handleQuantityDec(book.variant.id);
                      }}
                    >
                      <img src={decrement} alt="minus" />
                    </div>
                    <p>{book.quantity}</p>
                    <div
                      className="quantity-in-decrementer"
                      onClick={() => {
                        handleQuantityIn(book.variant.id);
                      }}
                    >
                      <img src={increment} alt="plus" />
                    </div>
                  </div>
                </div>
                <div className="cart-book-amount">
                  <h6 className="cart-book-amount-title body2">{contentLanguage === 'eng' ? 'Amount' : 'Monto'}</h6>
                  <h6 className="cart-book-amount-dollar">${book.quantity * book.variant.price}</h6>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="cart-books-order-summary">
        <h5>{contentLanguage === 'eng' ? 'Order summary' : 'Resumen de pedido'}</h5>
        <div className="cart-summary-content">
          <h6 className="cart-shipping-fee-subtotal body2">{contentLanguage === 'eng' ? 'Sub-total' : 'Subtotal'}</h6>
          <h6 className="cart-book-amount-dollar">${checkout?.totalPrice}</h6>
        </div>
        <div className="cart-summary-content">
          <h6 className="cart-shipping-fee-subtotal body2">
            {contentLanguage === 'eng' ? 'Shipping fee' : 'Gastos de envío'}
          </h6>
          <h6 className="cart-book-amount-dollar">NA</h6>
        </div>
        <div className="cart-summary-content">
          <h6 className="cart-shipping-fee-subtotal bold body2">{contentLanguage === 'eng' ? 'Total' : 'Total'}</h6>
          <h6 className="cart-book-amount-dollar">${checkout?.totalPrice}</h6>
        </div>
      </div>
      <div className="cart-checkout-button-holder" onClick={handleCheckout}>
        <BlogButton buttonText={contentLanguage === 'eng' ? 'Proceed To Checkout' : 'Proceder al pago'} />
      </div>
    </div>
  );
}
