import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import logoImage from '../../assets/contact/logo.png';
import { homeSchemaSite, homeSchemaPage, homeSchemaOrganization, homeSchemaSiteElement } from '../../utils/homeSchema';
import defaultImage from '@assets/landing-page/background-hero-spanish.png';

const Seo = ({ description, lang, meta, title, seoImage, siteUrl }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
            image
          }
        }
      }
    `
  );

  const ogURL = siteUrl;
  const metaDescription = description ?? site.siteMetadata.description;

  const seo = {
    title: title,
    description: metaDescription,
    image: seoImage,
    url: ogURL,
  };

  return (
    <Helmet title={seo.title} titleTemplate={seo.title}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      <link rel="preconnect" href="https://connect.facebook.net" />
      <script type="application/ld+json">{JSON.stringify(homeSchemaSite)}</script>
      <script type="application/ld+json">{JSON.stringify(homeSchemaPage)}</script>
      <script type="application/ld+json">{JSON.stringify(homeSchemaOrganization)}</script>
      <script type="application/ld+json">{JSON.stringify(homeSchemaSiteElement)}</script>
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
