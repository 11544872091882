import React, { useContext } from 'react';

import LanguageContext from '../../context/language-context';

export default function LanguageMenu({ showLanguage, setShowLanguage }) {
  const { contentLanguage, setContentLanguage } = useContext(LanguageContext);

  return (
    <div className="language-menu-container">
      <ul>
        <li
          className="options-text options-text-modified"
          onClick={() => {
            setContentLanguage('eng');
            setShowLanguage(!showLanguage);
          }}
        >
          <h6>English</h6>
        </li>
        <li
          className="options-text"
          onClick={() => {
            setContentLanguage('esp');
            setShowLanguage(!showLanguage);
          }}
        >
          <h6>Spanish</h6>
        </li>
      </ul>
    </div>
  );
}
