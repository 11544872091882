export const homeSchemaSite = {
  '@context': 'https://schema.org',
  '@graph': [
    {
      '@type': 'WebSite',
      '@id': 'https://www.betterwayoflife.com/#website',
      url: 'https://www.betterwayoflife.com/',
      name: 'Better Way of Life',
      inLanguage: 'en-US',
    },
    {
      '@type': 'Organization',
      '@id': 'https://www.betterwayoflife.com/#author',
      name: 'Better Way of Life',
      url: 'https://www.betterwayoflife.com/',
      Logo: {
        '@type': 'ImageObject',
        '@id': 'https://www.betterwayoflife.com/#logo',
        url: 'https://www.betterwayoflife.com/',
        caption: 'Better Way of Life',
        inLanguage: 'en-US',
      },
    },
    {
      '@type': 'ImageObject',
      '@id': 'https://www.betterwayoflife.com/',
      url: 'https://www.betterwayoflife.com/',
      caption: 'Better Way of Life',
      inLanguage: 'en-US',
    },
    {
      '@type': 'WebPage',
      name: 'Better Way of Life',
      '@id': 'https://www.betterwayoflife.com/#WebPage',
      url: 'https://www.betterwayoflife.com/',
      description:
        'Become Your Best Self by Exploring Our Healthy Living Books. Purchase Your Favorites at the Best Price from Your Favorite Bookstore Now!!',
      publisher: {
        '@type': 'Person',
        name: 'Better Way of Life',
      },
      author: {
        '@id': 'https://www.betterwayoflife.com/#author',
      },
      isPartOf: {
        '@id': 'https://www.betterwayoflife.com/#website',
      },
      primaryImageOfPage: {
        '@id': 'https://www.betterwayoflife.com/',
      },
      inLanguage: 'en-US',
    },
  ],
};

export const homeSchemaPage = {
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  '@id': 'https://www.betterwayoflife.com/#ContentSchema',
  headline: 'Bookstore: Best Healthy Living Books - Better Way of Life',
  url: 'https://www.betterwayoflife.com/',
  about: [
    { '@type': 'thing', name: 'Healthy Living', sameAs: 'https://en.wikipedia.org/wiki/Healthy_Living' },
    { '@type': 'thing', name: 'Bookselling', sameAs: 'https://en.wikipedia.org/wiki/Bookselling' },
    { '@type': 'thing', name: 'Wellness', sameAs: 'https://en.wikipedia.org/wiki/Wellness' },
    {
      '@type': 'thing',
      name: 'Health and wellness books',
      sameAs: 'https://en.wikipedia.org/wiki/Category:Health_and_wellness_books',
    },
    { '@type': 'thing', name: 'United States', sameAs: 'https://en.wikipedia.org/wiki/United_States' },
    {
      '@type': 'thing',
      name: 'Books about health',
      sameAs: 'https://en.wikipedia.org/wiki/Category:Books_about_health',
    },
  ],
};

export const homeSchemaOrganization = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  url: 'https://www.betterwayoflife.com/',
  name: 'Better Way of Life',
  email: 'hhes@hhesbooks.com',
  logo: {
    url: 'https://www.betterwayoflife.com/',

    '@context': 'http://schema.org',
    '@type': 'ImageObject',
  },
};

export const homeSchemaSiteElement = {
  '@context': 'http://schema.org',
  '@type': 'WebSiteElement',
  SiteNavigationElement: [
    {
      '@type': 'SiteNavigationElement',
      name: 'Better Way of Life',
      url: 'https://www.betterwayoflife.com/',
    },
    { '@type': 'SiteNavigationElement', name: 'Home', url: 'https://www.betterwayoflife.com/' },
    { '@type': 'SiteNavigationElement', name: 'Blogs', url: 'https://www.betterwayoflife.com/blog/' },
    { '@type': 'SiteNavigationElement', name: 'Books', url: 'https://www.betterwayoflife.com/books/' },
    { '@type': 'SiteNavigationElement', name: 'About', url: 'https://www.betterwayoflife.com/about-us/' },
    { '@type': 'SiteNavigationElement', name: 'Contact', url: 'https://www.betterwayoflife.com/contact/' },
    { '@type': 'SiteNavigationElement', name: 'Knowledge', url: 'https://www.betterwayoflife.com/blog/' },
  ],
};
