import React from 'react';

const ShoppingBag = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 2.14111L3 6.14111V20.1411C3 20.6715 3.21071 21.1803 3.58579 21.5553C3.96086 21.9304 4.46957 22.1411 5 22.1411H19C19.5304 22.1411 20.0391 21.9304 20.4142 21.5553C20.7893 21.1803 21 20.6715 21 20.1411V6.14111L18 2.14111H6Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 6.14111H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M16 10.1411C16 11.202 15.5786 12.2194 14.8284 12.9695C14.0783 13.7197 13.0609 14.1411 12 14.1411C10.9391 14.1411 9.92172 13.7197 9.17157 12.9695C8.42143 12.2194 8 11.202 8 10.1411"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ShoppingBag;
