import React from 'react';

import PrinterIcon from '@assets/recepies/printer-icon.svg';
import { Loader } from '@components/loader/loader';

const BlogButton = ({ buttonText, print, isLoading, disable, bgcolor }) => {
  return (
    <div className={disable ? 'blog-reusable-button disable' : 'blog-reusable-button'} id={bgcolor ? 'whiteBG' : ''}>
      {print ? (
        <div className="button-icon">
          <img src={PrinterIcon} />
        </div>
      ) : (
        ''
      )}

      {isLoading ? (
        <div className="loader-container-button">
          <Loader />
        </div>
      ) : (
        buttonText
      )}
    </div>
  );
};

export default BlogButton;
