import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'gatsby';

import Closeicon from '@assets/browse-books/icon-close.svg';
import Facebook from '@assets/social-icons/facebook';
import Twitter from '@assets/social-icons/twitter';
import LinkedIn from '@assets/social-icons/linkedin';
import LanguageContext from '../../context/language-context';

export default function PopMenuHeader({ setShowMenu, showMenuStatus, enableScroll }) {
  const { contentLanguage } = useContext(LanguageContext);

  const contentResources = {
    eng: {
      home: 'Home',
      books: 'Books',
      about: 'About',
      contact: 'Contact',
      knowledge: 'Blogs',
      faqs: 'FAQS',
      social: 'Social',
      getInTouch: 'Get In Touch',
    },
    esp: {
      home: 'Hogar',
      books: 'Libros',
      about: 'Sobre',
      getInTouch: 'Ponerse en contacto',
      knowledge: 'Blogs',
      contact: 'Contacto',
      faqs: 'FAQS',
      social: 'Social',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);
  const [socialIconColor, setSocialIconColor] = useState({
    facebook: 'black',
    twitter: 'black',
    linkedIn: 'black',
  });
  return (
    <div className={showMenuStatus ? 'popup-menu-header-container' : 'popup-menu-header-container-hide'}>
      <div className="pop-up-nav-options" onClick={() => enableScroll()}>
        <div
          className="pop-up-close-icon"
          onClick={() => {
            setShowMenu(!showMenuStatus);
            enableScroll();
          }}
        >
          <img src={Closeicon} alt="cross" />
        </div>
        <Link to="/">
          <h4 className="mobile-h4 margin-top-4 " id="hover-animation">
            {currentContent?.home}
          </h4>
        </Link>
        <Link to="/books">
          <h4 className="mobile-h4 margin-top-4" id="hover-animation">
            {currentContent?.books}
          </h4>
        </Link>
        <Link to="/about-us">
          <h4 className="mobile-h4 margin-top-4" id="hover-animation">
            {currentContent?.about}
          </h4>
        </Link>
        <Link to="/contact">
          <h4 className="mobile-h4 margin-top-4" id="hover-animation">
            {currentContent?.contact}
          </h4>
        </Link>
        <Link to="/blog">
          <h4 className="mobile-h4 margin-top-4" id="hover-animation">
            {currentContent?.knowledge}
          </h4>
        </Link>
        <Link to="/faqs">
          <h4 className="mobile-h4 margin-top-4 hide" id="hover-animation">
            {currentContent?.faqs}
          </h4>
        </Link>
      </div>
      <div className="pop-up-get-intouch">
        <h5>{currentContent?.getInTouch}</h5>
        <a href="mailto: hhes@hhesbooks.com">
          <p className="body-2 mail-link-popup"> hhes@hhesbooks.com</p>
        </a>
      </div>
      <div className="pop-up-social-link">
        <h5 className="mobile-h4">{currentContent?.social}</h5>
        <div className="pop-up-social-icons">
          <a
            style={{ transitionDelay: '1s' }}
            // href="https://www.facebook.com/"
            onMouseOver={() => setSocialIconColor((preState) => ({ ...preState, facebook: '#F15E32' }))}
            onMouseLeave={() => setSocialIconColor((preState) => ({ ...preState, facebook: 'black' }))}
          >
            <Facebook color={socialIconColor.facebook} />
          </a>
          <a
            onMouseOver={() => setSocialIconColor((preState) => ({ ...preState, twitter: '#F15E32' }))}
            onMouseLeave={() => setSocialIconColor((preState) => ({ ...preState, twitter: 'black' }))}
            // href="https://www.twitter.com/"
          >
            <Twitter color={socialIconColor.twitter} />
          </a>
          <a
            onMouseOver={() => setSocialIconColor((preState) => ({ ...preState, linkedIn: '#F15E32' }))}
            onMouseLeave={() => setSocialIconColor((preState) => ({ ...preState, linkedIn: 'black' }))}
            // href="https://www.linkedin.com/"
          >
            <LinkedIn color={socialIconColor.linkedIn} />
          </a>
        </div>
      </div>
    </div>
  );
}
